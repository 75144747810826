import React from "react"
import SEO from "../../../components/seo"
import { Helmet } from 'react-helmet'
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"

const developersContent = {
  title: "Chargeback calculator",
  description: [
    {
      text: "Our free chargeback calculator helps you understand the impact of chargebacks on the revenue of your subscription business. You can calculate how much you lose to both refunds and chargeback fees, account for refunds provided by your payment gateway when you win and see how much you lose to chargebacks."
    }
  ],
  sectionCalculator: true,
  image: require('../../../images/tools-chargeback-calculator.svg'),
  svg: true,
  isBanner: true,
  imageName: 'developers svg',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right'
}

const chargeBackCalc = () => {
  return (
    <Layout className="chargeback-calc">
      <SEO 
        title="Chargeback Calculator" 
        description="Calculate the impact of chargebacks on your subscription business and understand how it'll impact your recurring revenue payments over time." 
        url="https://www.billsby.com/resources/tools/chargeback-calculator"
      />

      <TwoColumn content={developersContent}/>

        <div className="container">
          <div className="calc">
          <div className="calculator" data-calc-id="hoAGbHNWe4R3BWdKZ"></div>
            <Helmet>
              <script
                async={true}
                type="text/javascript"
                id="convertcalculator-embedder-axP7WcQqWW5QBboJh" 
                className="convertcalculator-async-script-loader"
                src="https://app.convertcalculator.co/embed.js"
              ></script>
            </Helmet>
            {/* <div className="calculator" data-calc-id="hoAGbHNWe4R3BWdKZ"></div>

            <Helmet>
              <script
                type="text/javascript" 
                id="convertcalculator-embedder-axP7WcQqWW5QBboJh" className="convertcalculator-async-script-loader"
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: `
                  (function() { function async_load(){ var s = document.createElement("script"); s.type = "text/javascript"; s.async = true; var url = 'https://app.convertcalculator.co/embed.js'; s.src = url + ( url.indexOf("?") >= 0 ? "&" : "?") + "ref=" + encodeURIComponent(window.location.href); var embedder = document.getElementById('convertcalculator-embedder-axP7WcQqWW5QBboJh'); embedder.parentNode.insertBefore(s, embedder); } if (window.attachEvent) window.attachEvent("onload", async_load); else window.addEventListener("load", async_load, false); })();
                  `,
                }}
              />
            </Helmet> */}
          </div>
        </div>
    </Layout>
  )
}

export default chargeBackCalc